@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,600;0,800;1,700&display=swap');

/* FONTS */
$barlow: 'Barlow', sans-serif;
$font: $barlow;

/* danny phantom */
$alternative: #a3f7b5;
$secondary-color: #0f1f2e;
$primary-color: #ebfbff;

.revision {
  bottom:0;
  padding-bottom: 10px;
  position: fixed;
  font-size: 14px;
}

h2 {
  font-weight: 800;
  color: $alternative;
}

h3 {
  font-weight: 600;
  font-size: 1.5rem;
  color: $alternative;
  margin: 0;
}

h4 {
  font-weight: 600;
  font-size: 1.5rem;
}

p {
  font-size: 1.25rem;
}

.bold {
  font-weight: 800;
}

body {
  font-family: $font !important;
  background-color: $secondary-color;
  font: 24px "Century Gothic", Futura, sans-serif;
  margin: 25px;
  color: $primary-color;
}

hint {
  opacity: 0.8;
}

.icons {
  margin-top: 40vh;
  text-align: center;
}

.fa-link {
  text-decoration: 'none';
  padding-left: 10px;
  padding-right: 10px;
  font-size: 36px;
}

.fa-link:hover {
  color: $alternative;
}

h1 {
  padding: 0rem;
  font-size: 5rem;
  margin: 0rem;
}

h2 {
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}

.fadeOut{
  opacity:0;
  width:0;
  height:0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn{
  opacity:1;
  width:100px;
  height:100px;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.homepage {
  min-height: 100vh;
}

/* SPOTIFY PAGE */
.p-5 {
  padding: 5%;
}

.album-art {
  width: 100%;
  transition: 0.75s;
}

.album {
  @media screen and (max-width: 725px) {
    width:33%;
  }
  transition: 0.75s;
}

.album:hover {
  .album-art {
    opacity: 0.5;
  }
}

/* LETTERBOX page */
.reviews {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.review {
  cursor: pointer;
  @media screen and (max-width: 710px) {
    padding: 0.5rem;
    margin: 0.5rem;
    svg {
      font-size: 16px;
    }
  }
  border: 2px $alternative solid;
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  .reviewText {
    display: none;
  }
}

.review:hover {
  border-color: $primary-color;
}

.review-detailed {
  display: none;
  border: 2px $alternative solid;
  margin: 1rem;
  padding: 2rem;
  border-radius: 0.5rem;

  @media screen and (max-width: 605px) {
    font-size: 18px;
  }
  .close {
    margin-bottom: 1rem;
    color: $primary-color;
    float: right;
    font-size: 36px;
  }
  
  .close:hover,
  .close:focus {
    color: $alternative;
    text-decoration: none;
    cursor: pointer;
  }
}

.commentIcon {
  float: right;
}

.poster-wrapper {
  height: 23rem;
  width: 16rem;
  @media screen and (max-width: 710px) {
    height: 9rem;
    width: 7rem;
  }
}

.poster {
  border-radius: 0.5rem;
  width: 16rem;
  @media screen and (max-width: 710px) {
    width: 7rem;
  }
}

.ul-title {
  color: $primary-color;
  text-decoration: underline $alternative;
  text-decoration-thickness: 3px;
  font-style: italic;
  margin-right: 0.5rem;
}

blockquote {
  font-style: italic;
  border-left-width: 4px;
  border-left-style: solid;
  margin: 2rem 1rem 2rem 1rem;
  padding-left: 2rem;
  color: inherit;
}

select {
  font-family: $barlow;
  font-size: inherit;
  text-decoration: underline $alternative 0.2rem;
  margin-left: 0.25rem;
  background-color: inherit;
  border-radius: 0.5rem;
  color: $primary-color;
  border: $alternative 0.0rem solid;
}

.theme-icon {
  transition: transform .75s ease-in-out;
  position: absolute;
  right: 0;
  top: 0;
  margin: 2rem;
  cursor: pointer;
}

.theme-icon:hover {
  transform: rotate(720deg);
  color: $alternative;
}
